import {
  SessionControlResult,
  SessionControlType,
} from "@features/sessions/types";
import { getFirstNodes } from "@features/sessions/utils";
import {
  ExclamationCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";

type ControlProps = {
  title: string;
  description?: string;
  controls?: SessionControlType[];
  result: SessionControlResult;
  className?: string;
  depth?: number;
  last?: boolean;
};

export function Control({
  controls,
  className,
  title,
  description,
  result,
  depth = 0,
  last = false,
}: ControlProps) {
  const nodes = getFirstNodes(controls || []);
  return (
    <div
      className={twMerge(depth <= 0 && "my-3 relative z-0", className, "grow")}
      data-tooltip={description}
    >
      <div className={twMerge("flex relative", depth > 0 ? "ml-6" : "")}>
        {depth > 0 && (
          <div className="absolute flex flex-col justify-stretch w-4 h-16 -left-3 -top-2">
            <div className="border-l flex-1 border-b"></div>
            <div className={twMerge("flex-1", !last ? "border-l" : "")}></div>
          </div>
        )}
        <div
          className={twMerge("flex gap-2 items-center", depth > 0 && "mt-3")}
        >
          {result === SessionControlResult.Success && (
            <CheckCircleIcon
              className={twMerge(
                "shrink-0 h-6 w-6 z-10",
                "text-green-500",
                depth > 0 ? "h-5 w-5" : "h-6 w-6"
              )}
            />
          )}
          {result === SessionControlResult.Warning && (
            <ExclamationCircleIcon
              className={twMerge(
                "shrink-0 h-6 w-6 z-10",
                "text-orange-500",
                depth > 0 ? "h-5 w-5" : "h-6 w-6"
              )}
            />
          )}
          {result === SessionControlResult.Error && (
            <XCircleIcon
              className={twMerge(
                "shrink-0 h-6 w-6 z-10",
                "text-red-500",
                depth > 0 ? "h-5 w-5" : "h-6 w-6"
              )}
            />
          )}
          {result === SessionControlResult.Undefined && (
            <MinusCircleIcon
              className={twMerge(
                "shrink-0 h-6 w-6 z-10",
                "text-slate-500",
                depth > 0 ? "h-5 w-5" : "h-6 w-6"
              )}
            />
          )}
          <span className={twMerge("", depth > 0 ? "text-slate-400" : "")}>
            {title}
          </span>
        </div>
      </div>
      <div className={twMerge("relative", depth > 0 ? "ml-6" : "")}>
        {depth > 0 && (
          <div className="absolute flex flex-col justify-stretch w-4 h-full -left-3">
            {!last && <div className="border-l flex-1"></div>}
          </div>
        )}
        {nodes?.map((subControl, index) => (
          <Control
            key={subControl.identifier}
            depth={depth + 1}
            result={subControl.result}
            title={subControl.titleMsg}
            last={index >= nodes.length - 1}
            description={subControl.details}
            controls={controls?.filter(
              (control) =>
                control.identifier.startsWith(subControl.identifier) &&
                control != subControl
            )}
          />
        ))}
      </div>
    </div>
  );
}
