import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Info, Section, Title } from "@atoms/text";
import Assignment from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { useCustomerBy } from "@features/customers/state/use-customer-by";
import { ROUTES } from "@features/routes";
import { useSession } from "@features/sessions/state/use-session";
import { Bars4Icon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import IdentityDocumentReview from "./components/identity-document-review";
import SessionCustomerOverview from "./components/session-customer-overview";
import SessionSummary from "./components/session-summary";
import { SessionDecisionsTimeline } from "./components/timeline";
import FaceMatchReview from "./components/face-match-review";
import WatchListReview from "./components/session-watchlist-review";
import SessionLocationReview from "./components/session-location-review";
import SessionCaptchaReview from "./components/session-captcha-review";
import ScenarioMonitoring from "./components/session-logs";
import { QuestionType } from "@features/scenarios/types";
import { SessionInformationsReview } from "./components/session-informations-review";
import { AlertType, SessionType } from "@features/sessions/types";
import { SessionDocuments } from "./components/session-documents";
import Card from "@atoms/card";
import Tabs from "@atoms/tabs";

export default function SessionDetailsPage() {
  const { id } = useParams();
  const { session, loading, refresh } = useSession(id || "");
  const { customer } = useCustomerBy({
    external_ids: session?.session?.external_id || "",
  });

  useEffect(() => {
    try {
      refresh();
    } catch (e) {
      console.error(e);
    }
  }, [id, refresh]);

  if (!session && loading) return <PageLoader />;
  if (!session)
    return (
      <Page>
        <Info className="w-full h-full text-center">
          This session does not exist.
        </Info>
      </Page>
    );

  const finalDecision = ((session.decisions ?? []) as any).findLast(
    (d: any) => d.last
  );
  return (
    <Page>
      <Title className="flex flex-row mb-4">
        <div className="grow">Session details</div>
        <div className="float-right">
          <div className="m-auto inline-flex items-center">
            <Link className="flex" to={ROUTES.Sessions}>
              <Button size="sm" theme="outlined">
                <Bars4Icon className="h-5 w-5 mr-2" /> Back to all sessions
              </Button>
            </Link>
          </div>
        </div>
      </Title>
      <SessionSummary
        id={id}
        sessionCustomer={session.onboarding_customer}
        details={session.session}
        scoring={session.scoring}
        decision={finalDecision}
        alertsSummary={session.alerts as any}
        scoringPreferences={session.scoring_preferences_used}
      ></SessionSummary>

      {session.session?.state?.toLocaleLowerCase() !== "completed" && (
        <Card
          className="bg-slate-400 mt-4"
          text={
            <div className="text-white text-center flex flex-row items-center justify-center">
              <InformationCircleIcon className="h-5 w-5 inline mr-2" />
              This session is not completed yet.
            </div>
          }
        />
      )}

      <div className="mt-4 flex flex-row h-full space-x-2">
        <div className="w-1/4 min-w-1/4 flex flex-col space-y-4">
          <div>
            <Assignment
              id={id!}
              type="session"
              groups={session?.review_groups || []}
            />
          </div>
          <div>
            <SessionDecisionsTimeline
              session_id={session.session?.session_id}
            />
          </div>
        </div>
        <div className="grow mt-4 flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <Navigation session={session} />

            {session.alerts.find((a) => a.code === "identity")?.score !==
              undefined && (
              <IdentityDocumentReview
                alert={session.alerts.find((a) => a.code === "identity")!}
                anonymized={session.session.anonymized}
              />
            )}
            {session.alerts.find((a) => a.code === "face")?.score !==
              undefined && (
              <FaceMatchReview
                alert={session.alerts.find((a) => a.code === "face")!}
                anonymized={session.session.anonymized}
              />
            )}
            {session.questions.filter(
              (a) => a.type === QuestionType.Information
            ).length > 0 && (
              <SessionInformationsReview
                alert={
                  session.alerts.find((a) => a.code === AlertType.Information)!
                }
              />
            )}
            {session.documents.length > 0 && (
              <SessionDocuments documents={session.documents} />
            )}
            {session.scoring_preferences_used?.watchlist?.enabled && (
              <WatchListReview
                alert={session.alerts.find((a) => a.code === "watchlist")!}
                sessionCustomer={session.onboarding_customer}
                scoringPreferences={session.scoring_preferences_used}
              />
            )}
            {session.scoring_preferences_used?.geolocation?.enabled && (
              <SessionLocationReview
                alert={session.alerts.find((a) => a.code === "location")!}
                anonymized={session.session.anonymized}
                ip={session.session.ip}
              />
            )}
            {session.scoring_preferences_used?.captcha?.enabled && (
              <SessionCaptchaReview
                alert={session.alerts.find((a) => a.code === "captcha")!}
              />
            )}
          </div>

          {customer?.data?.id && (
            <SessionCustomerOverview customer_id={customer?.data?.id} />
          )}
          <div className="flex flex-col space-y-2">
            <Section className="">Session logs</Section>
            <ScenarioMonitoring monitorings={session.monitoring} />
          </div>
        </div>
      </div>
    </Page>
  );
}

const Navigation = ({ session }: { session: SessionType }) => {
  const navRef = useRef<any>(null);
  const parentRef = useRef<any>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (navRef.current) {
          if (!entry.isIntersecting) {
            const parentNode = navRef.current.parentNode;
            navRef.current.style.position = "fixed";
            navRef.current.style.top = "50px"; // Adjust as needed
            navRef.current.style.width = parentNode.clientWidth + "px";
            navRef.current.style.left =
              parentNode.getBoundingClientRect().left + "px";
          } else {
            navRef.current.style.position = "static";
          }
        }
      },
      { threshold: 0 }
    );

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        observer.unobserve(parentRef.current);
      }
    };
  }, []);

  return (
    <div ref={parentRef} className="relative w-full">
      <div ref={navRef} style={{ zIndex: 10 }}>
        <Tabs
          parentClassName="text-white hover:text-white hover:underline"
          className="bg-slate-500 shadow rounded-full px-4 border-none"
          tabs={[
            ...(session.alerts.find((a) => a.code === "identity")?.score
              ? ([
                  {
                    value: "identity-question",
                    label: "Identity",
                  },
                ] as any)
              : []),
            ...(session.alerts.find((a) => a.code === "face")?.score
              ? ([
                  {
                    value: "face-question",
                    label: "Face",
                  },
                ] as any)
              : []),
            ...(session.questions.filter(
              (a) => a.type === QuestionType.Information
            ).length > 0
              ? ([
                  {
                    value: "information-question",
                    label: "Scenario",
                  },
                ] as any)
              : []),
            ...(session.documents.length > 0
              ? ([
                  {
                    value: "documents",
                    label: "Documents",
                  },
                ] as any)
              : []),
            ...(session.scoring_preferences_used?.watchlist?.enabled
              ? ([
                  {
                    value: "watchlist",
                    label: "Watchlist",
                  },
                ] as any)
              : []),

            ...(session.scoring_preferences_used?.geolocation?.enabled
              ? ([
                  {
                    value: "location",
                    label: "Location",
                  },
                ] as any)
              : []),
            ...(session.scoring_preferences_used?.captcha?.enabled
              ? ([
                  {
                    value: "captcha",
                    label: "Captcha",
                  },
                ] as any)
              : []),
            {
              value: "customer",
              label: "Customer",
            },
          ]}
          onChange={(tab) => {
            // Find the element by ID
            const element = document.getElementById(tab as string);
            if (element) {
              // Find closest "overflow-auto" parent
              const scrollable = element.closest(".overflow-auto");
              if (scrollable) {
                // Get the element's position relative to the top of the page
                const offsetTop = element.offsetTop;

                // Scroll to 100px before the element smoothly
                scrollable.scrollTo({
                  top: offsetTop - 120, // Adjust by 100px
                  behavior: "smooth",
                });
              }
            }
          }}
          value={"jump"}
        />
      </div>
    </div>
  );
};
