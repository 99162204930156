import { QuestionType, ScoringPreferences } from "@features/scenarios/types";

export type SessionListType = {
  session_id: string;
  external_id: string;
  scenario_ref: string;
  language: string;
  start_timestamp: string;
  end_timestamp: string;
  consent_timestamp: string;
  state: string;
  customer_firstname: string;
  customer_lastname: string;
  status: string | null;
  score: number;
  success: boolean;
  fail: boolean;
  risk: boolean;
  sort_name: string;
  warnings: number[] | null;
};

export type SessionCustomerAvatar = {
  type: "video" | "image";
  url: string;
};

export type SessionCustomerType = {
  customer_id: string;
  external_id: string;
  firstname: string;
  lastname: string;
  phone: string;
  birthdate: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  avatar?: SessionCustomerAvatar;
};

export type SessionQuestionAnswerVideoType = {
  question_id: number;
  question_template_id: number;
  try: number;
  from: number;
  to: number;
  file_name: string;
  url: string;
};

export type SessionQuestionAnswerAttachmentType = {
  type: SessionDocumentType;
  id: string;
};

export type SessionQuestionAnswerAnalysisResultType = {
  id_type: string;
  document_detail: {
    emit_country: string;
    emit_date: string;
    expiration_date: string;
    document_number: string;
    extra_infos: Record<string, string> | null;
  };
  holder_detail: {
    last_name: string[];
    first_name: string[];
    nationality: string;
    gender: string;
    birth_date: string;
    birth_place: string;
  };
  controls: SessionControlType[];
  mrz: string[];
};

export type SessionQuestionAnswerIdentityContentType = {
  id: number;
  has_two_sides: boolean;
  raw_format: string;
  analysis_result: SessionQuestionAnswerAnalysisResultType;
};

export enum SessionAlertStatus {
  Failed = "FAILED",
  Consider = "CONSIDER",
  Success = "SUCCESS",
  Undefined = "UNDEFINED",
}

export enum SessionControlResult {
  Success = "SUCCESS",
  Error = "ERROR",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
}

export type SessionControlType = {
  identifier: string;
  titleMsg: string;
  resultMsg: string;
  result: SessionControlResult;
  score: number;
  details: string;
  controls?: SessionControlType[];
  control?: SessionControlType[]; // Previous version compatibility
};

export type SessionQuestionAnswerFaceContentType = {
  face: {
    id: number;
    cropped_format: string;
  }[];
  controls: SessionControlType[];
  version?: number;
};

export type SessionQuestionAnswerType = {
  date_start: Date;
  eval_result: boolean;
  videos: SessionQuestionAnswerVideoType[];
  attachment_urls: SessionQuestionAnswerAttachmentType[];
  identity_face?: SessionQuestionAnswerAttachmentType; // Why here ?
  identity_content?: SessionQuestionAnswerIdentityContentType;
  face_contents?: SessionQuestionAnswerFaceContentType;
  information_data: any;
  file_upload_content?: FileUploadContent;
};

export type FileUploadContent = {
  file_name: string;
  file_type: string;
  file_path: string;
  file_size: number;
};

export type SessionQuestionInstanceType = {
  id: number;
  date_start: Date;
  answers: SessionQuestionAnswerType;
  status: SessionStatus | null;
};

export type SessionQuestionType = {
  id: number;
  type: QuestionType;
  label: string;
  description_localized: {
    [key: string]: string;
  };
  coefficient: number;
  language: string;
  instances: SessionQuestionInstanceType[];
  data: any;
};

export type SessionDetailsType = {
  external_id: string;
  scenario_ref: string;
  scenario_id: number;
  session_id: string;
  scenario_revision_id: number;
  date_start: string;
  consent_date: string;
  date_end: string;
  language: string;
  state:
    | "init"
    | "consent"
    | "input_data"
    | "identity_document_type"
    | "scenario"
    | "completed"
    | "error";
  ip: string;
  video_url: string;
  video_type: string;
  warnings: number[];
  anonymized: boolean;
};

export type SessionType = {
  onboarding_customer: SessionCustomerType;
  session: SessionDetailsType;
  review_groups: string[] | null;
  alerts: SessionAlertType[];
  questions: SessionQuestionType[];
  scoring: SessionScoringType;
  risk_and_compliance: SessionRiskAndComplianceType;
  scoring_preferences_used: ScoringPreferences;
  captcha?: SessionCaptchaType;
  geolocation: SessionGeolocationType;
  decisions: SessionDecisionType[];
  version?: number;
  monitoring: string[];
  documents: SessionDocument[];
};

export type SessionScenarioMonitoringType = {
  time: string;
  service: string;
  type: string;
  message: string;
  data: any;
};

export type SessionGeolocationType = {
  geocode: {
    latitute: number;
    longitude: number;
  };
  geocode_distance: number;
  location: {
    country_code: string;
    country_name: string;
    reegion_code: string;
    region_name: string;
    city: string;
    zip: string;
    latitute: number;
    longitude: number;
  };
  status: SessionStatus | null;
};

export type SessionCaptchaType = {
  final_result: number;
  controls: SessionControlType[];
  status: SessionStatus | null;
};

export type SessionRiskAndComplianceType = {
  found: boolean;
  result: {
    // Dow Jones Here
    head: {
      "api-version": string;
      "backend-version": string;
      "total-hits": number;
      "hits-from": number;
      "cached-results-id": string;
      truncated: boolean;
    };
    body: {
      match: any;
    };
  };
  status: SessionStatus | null;
};

export enum SessionStatus {
  Undefined = 0,
  Failed = 1,
  Pending = 2,
  Success = 3,
}

export enum AlertType {
  Face = "face",
  Identity = "identity",
  Location = "location",
  Watchlist = "watchlist",
  Captcha = "captcha",
  Scenario = "scenario",
  Information = "information",
  FileUpload = "file_upload",
}

export const AlertTypes = [
  AlertType.Face,
  AlertType.Identity,
  AlertType.Location,
  AlertType.Watchlist,
  AlertType.Captcha,
  AlertType.Scenario,
  AlertType.Information,
  AlertType.FileUpload,
];

// {
//   "id": 33,
//   "comment": "Eliminatory: IP country and user defined country mismatch.",
//   "date": "2024-05-28T13:51:13Z",
//   "last": true,
//   "label": "FAILED",
//   "type": "NEGATIVE"
// }
export type SessionDecisionType = {
  id: number;
  agent_id?: number;
  agent_name?: string;
  session_id: string;
  comment: string;
  status_id: number;
  date: string;
  last: boolean;
  label?: string;
  type: "POSITIVE" | "NEUTRAL" | "NEGATIVE" | "UNDEFINED";
};

export type SessionScoringType = {
  final_result: number;
  responses: SessionQuestionScoringType[];
  explanation: string;
};

export type SessionQuestionScoringType = {
  id: number;
  score: number;
  eliminatory: boolean;
  intermediate_scoring?: Record<string, number>;
};

export type SessionLocationControlType = {
  status: string;
  score: number;
  check: {
    country_match: string;
    precise_geolocation: string;
    max_distance_exceeded: string;
  };
  info: {
    customer: any;
    system: any;
  };
};

export type SessionQuestionInformation = {
  question_name: string;
  question_content: string;
  tries: SessionQuestionInformationTry[];
  coefficient: number;
  possible_answers: string[];
  should_not_contain: string[];
};

export type SessionQuestionInformationTry = {
  score?: number;
  answer: string;
  result: boolean;
  video: SessionQuestionAnswerVideoType;
};

export enum SessionDocumentType {
  Upload = "uploaded_file",
  Identity = "identity_face",
  IdentityBack = "identity_back",
  IdentityFront = "identity_front",
  Face = "face",
}

export type SessionDocument = {
  is_last_try?: boolean;
  try_index?: number;
  id: string;
  url: string;
  name: string;
  type: QuestionType;
  file_name?: string;
  file_type: string;
  file_size?: number;
  previews: string[];
};

export type SessionAlertType = {
  code: string;
  label: string;
  score?: number;
  eliminatory: boolean;

  state: SessionAlertStatus;

  controls?: SessionControlType[];
  question?: SessionQuestionInstanceType;
  video?: SessionQuestionAnswerVideoType;

  // Face
  identity_face?: SessionQuestionAnswerAttachmentType;

  //Identity
  intermediate_scoring?: Record<string, number>;
  identity_content?: SessionQuestionAnswerAnalysisResultType;

  //Watchlist
  dow_jones_result?: any;

  // location
  location_controls?: SessionLocationControlType;

  // informations
  informations?: SessionQuestionInformation[];

  uploads?: SessionDocument[];

  // secenario
  questions_eliminatory?: {
    question: SessionQuestionType;
    instance?: SessionQuestionInstanceType;
    scoring: SessionQuestionScoringType;
  }[];
  questions_worst?: {
    question: SessionQuestionType;
    instance?: SessionQuestionInstanceType;
    scoring: SessionQuestionScoringType;
  }[];
  questions_information?: {
    question: SessionQuestionType;
    instance?: SessionQuestionInstanceType;
    scoring: SessionQuestionScoringType;
  }[];
};

export type SessionsRequestOptions = {
  external_id: string | null;
  search: string | null;
  language: string | null;
  result_label: string | null;
  date_from: string | null;
  date_to: string | null;
  status_id: number | null;
  exclude_if_status: boolean;
  include_unfinished: boolean;
  origin: ["vid", "pix"];
  score_min: number | null;
  score_max: number | null;
  scenario_ref: string | null;
  review_groups: null | string[];
  only_without_review_groups: boolean;
  assigned_to_member_id: null | number;
  only_without_assignment: boolean;
  only_last: boolean;
  on_page: number;
  per_page: number;
  order_by: string | null;
  order_way: "DESC" | "ASC";
};

export type SessionStatusType = {
  id: number;
  label: string;
  type: "POSITIVE" | "NEUTRAL" | "NEGATIVE";
  active: boolean;
  is_auto: boolean;
};

export type CreateSessionInput = {
  scenario_code: string;
  external_id: string;
  language: string;
  extra_data?: string;
  input_data?: {
    firstname?: string;
    lastname?: string;
    phone?: string;
    birthdate?: Date | string;
    address?: string;
    zip?: string;
    country?: string;
    city?: string;
    custom_data?: { [key: string]: string };
  };
};

export type SessionSettings = {
  return_url: string;
  style_override_css: string;
  translation_override_json: string;
};
